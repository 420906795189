<template>
  <div class="home">
    <header class="Top">
      <div class="Logo">
        <img src="../assets/img/logoIcon.png" alt="" />
        <span>元艺星空</span>
      </div>
      <div class="introduce">
        元艺星空APP是一款数字藏品交易平台;<br />提供H5，安卓 IOS客户端;<br />平台主营商品涵盖数字艺术品、数字IP、数字门票等一系列藏品种类;<br />
        通过数字藏品收藏、欣赏与分享，致力于传统和现代文化的推广。
      </div>
      <img class="backImg" src="../assets/img/back.png" alt="" />
    </header>
    <div class="box navImg">
      <div class="tabText">优秀藏品展示</div>
      <div class="imgBox">
        <img src="../assets/img/Img1.png" alt="" />
        <img src="../assets/img/Img2.png" alt="" />
        <img src="../assets/img/Img3.png" alt="" />
      </div>
    </div>
    <div class="box flow">
      <div class="tabText">购买流程</div>
      <div class="imgBox">
        <img src="../assets/img/flowChart.png" alt="" />
      </div>
      <div class="imgBoxH">
        <img src="../assets/img/G1.png" alt="" />
        <img src="../assets/img/G2.png" alt="" />
        <img src="../assets/img/G3.png" alt="" />
      </div>
    </div>
    <footer class="footerBot">
      <a href="https://beian.miit.gov.cn/#/Integrated/index"
        >Copyright @ 2022 广州捷游软件有限公司 粤ICP备12041279号-13</a
      >
    </footer>
  </div>
</template>

<script>

export default {
  name: 'Home',
}
</script>
<style scoped lang="scss">
.home {
  width: 1440px;
  margin: 0 auto;
  @media screen and (max-width: 600px) {
    width: 375px;
  }
  .Top {
    width: 100%;
    background: #fff;
    text-align: left;
    padding: 61px 0 117px 0;
    @media screen and (max-width: 600px) {
      padding: 10px 0 99px 0;
    }
    .Logo {
      margin-left: 36px;
      @media screen and (max-width: 600px) {
        margin-left: 8px;
      }
      img {
        width: 38px;
        height: 17px;
        margin-right: 157px;
        @media screen and (max-width: 600px) {
          margin-right: 18px;
        }
      }
      span {
        font-size: 32px;
        font-weight: 800;
        @media screen and (max-width: 600px) {
          font-size: 20px;
        }
      }
    }
    .introduce {
      margin: 82px 0 0 231px;
      font-size: 16px;
      @media screen and (max-width: 600px) {
        width: 298px;
        margin: 32px 0 0 48px;
        font-size: 12px;
      }
    }
    .backImg {
      position: absolute;
      top: 125px;
      left: 1000px;
      @media screen and (max-width: 600px) {
        position: absolute;
        width: 125px;
        height: 125px;
        top: 190px;
        left: 200px;
      }
    }
  }
  .box {
    width: 100%;
    padding-top: 69px;
    @media screen and (max-width: 600px) {
      padding-top: 45px;
    }
  }
  .navImg {
    background: #141414;
    padding-bottom: 129px;
    @media screen and (max-width: 600px) {
      padding-bottom: 45px;
    }
    .tabText {
      margin: 0 0 109px 231px;
      color: #fff;
      font-size: 24px;
      text-align: left;
      font-weight: 700;
      @media screen and (max-width: 600px) {
        margin: 0 0 48px 32px;
        font-size: 16px;
      }
    }
    .imgBox {
      width: 1064px;
      display: flex;
      margin: 0 auto;
      justify-content: space-between;
      flex-wrap: wrap;
      @media screen and (max-width: 600px) {
        margin: 0;
        width: 76px;
      }
      img {
        width: 338px;
        height: 338px;
        @media screen and (max-width: 600px) {
          width: 300px;
          height: 300px;
          margin-left: 38px;
          margin-bottom: 18px;
        }
      }
    }
  }
  .flow {
    background: #fff;
    padding-bottom: 137px;
    .tabText {
      margin: 0 0 86px 231px;
      color: #333;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
      @media screen and (max-width: 600px) {
        margin: 0 0 48px 32px;
        font-size: 16px;
      }
    }
    .imgBox {
      width: 810px;
      height: 460px;
      margin: 0 auto;
      @media screen and (max-width: 600px) {
        display: none;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .imgBoxH {
      @media screen and (min-width: 600px) {
        display: none;
      }
      width: 313px;
      height: 177px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
  }

  .footerBot {
    height: 266px;
    background: #efefef;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @media screen and (max-width: 600px) {
      height: 100px;
    }
    a {
      color: #c9c9c9;
      font-size: 14px;
      margin-bottom: 34px;
      @media screen and (max-width: 600px) {
        font-size: 12px;
        margin-bottom: 34px;
      }
    }
  }
}
</style>
